// layout

.container{
    width: 100%;
    max-width: 1440px;
    padding: 0 16px;
    margin-inline: auto;
    &.container--full-width{
        max-width: 100%;
    }
}

.row--display, .row--form{
    &.row--form{
        &>div{
            margin-bottom: 24px;
        }
    }
    &>div{
        margin-bottom: 24px;
    }
    .label{
        color: $text-color;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 4px;
    }
    .value{
        color: $text-color;
        font-size: 14px;
        line-height: 17px;
    }
}

.note{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 16px;
    border-radius: 8px;
    &.note--success{
        background-color: #00A976;
    }
    &.note--white{
        background-color: #fff;
        color: $primary-color;
    }
}