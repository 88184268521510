// modals
.modal{
    &.confirm-action .modal-dialog{
        .modal-dialog{
            box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.20);
        }
    }
    &.modal--name .modal-dialog{
        max-width: 400px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    .modal-dialog{
        height: auto !important;
        overflow: auto !important;
        border-radius: 8px !important;
        margin-top: 80px;
        box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 25%);
        overflow: visible !important;
        .modal-content{
            overflow: visible !important;
            border-radius: 8px !important;
            border: none;
            overflow-x: hidden;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .modal-wrap{
            border-radius: 8px;
            background-color: #fff;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.modal-backdrop{
    background-color: #000 !important;
    &.modal-backdrop--route{
        z-index: 1040 !important;
    }
    &.modal-backdrop--confirmaction{
        z-index: 1041 !important;
    }
}
.modal--route{
    z-index: 1041 !important;
    background-color: rgba(0, 0, 0, 0.6);
}

// button.close-modal{
//     width: 24px;
//     height: 24px;
//     background-image: url(/assets/img/icons/close-modal.svg);
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: absolute;
//     top: 24px;
//     right: 24px;
// }