.underline{
    text-decoration: underline !important;
}
.no-underline{
    text-decoration: none !important;
}
.cursor-default{
    cursor: default !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.truncate{
    --line-height: 24px;
    --lines: 5;
    width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--lines);
    line-clamp: var(--lines);
    display: -webkit-box;

    &.truncate--7{
        --lines: 7;
        // -webkit-line-clamp: 7;
        // line-clamp: 7;
    }
    &.truncate--5{
        --lines: 5;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
    }
    &.truncate--4{
        --lines: 4;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
    }
    &.truncate--2{
        --lines: 2;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
    }
}
.pre-wrap{
    white-space: pre-wrap;
}
.hidden{
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
.no-pointer{
    pointer-events: none;
}
.saving{
    opacity: .6;
    pointer-events: none;
}
.saving--pre{
    transition: .15s ease;
}
.box-shadow{
    box-shadow: 0px 4px 12px 0px $box-shadow;
}
.position-relative{
    position: relative;
}
.link-color{
    color: $primary-color;
}

.content{
    width: 90%;
    max-width: 1124px;
    &.content--small{
        max-width: 720px;
    }
    &.content--center{
        margin: auto;
    }
}

.min-width-content{
    min-width: max-content;
}

//varibale padding spacing
.py-dynamic-large{
    padding-top: 40px;
    padding-bottom: 40px;
}
@media screen and (min-width: 992px) {
    .py-dynamic-large{
        padding-top: 62px;
        padding-bottom: 62px;
    }
}

// gaps
.gap-8{ gap: $spacing-8;}
.gap-16{ gap: $spacing-16;}
.gap-24{ gap: $spacing-24;}
.gap-32{ gap: $spacing-32;}
.gap-48{ gap: $spacing-48; }

// spacing padding helper classes
.mt-auto{margin-top: auto;}
.mt-8{margin-top: $spacing-8;}
.mb-8{margin-bottom: $spacing-8;}
.mt-24{margin-top: $spacing-24;}
.mb-24{margin-bottom: $spacing-24;}
.mt-36{margin-top: $spacing-36;}
.mb-36{margin-bottom: $spacing-36;}
.mt-48{margin-top: $spacing-48;}
.mb-48{margin-bottom: $spacing-48;}

.p-24{padding: $spacing-24;}
.pt-24{padding-top: $spacing-24;}
.pb-24{padding-bottom: $spacing-24;}
.pt-36{padding-top: $spacing-36;}
.pb-36{padding-bottom: $spacing-36;}
.pt-48{padding-top: $spacing-48;}
.pb-48{padding-bottom: $spacing-48;}

.py-8{padding-top: $spacing-8; padding-bottom: $spacing-8;}
.py-16{padding-top: $spacing-16; padding-bottom: $spacing-16;}
.py-24{padding-top: $spacing-24; padding-bottom: $spacing-24;}
.py-36{padding-top: $spacing-36; padding-bottom: $spacing-36;}


// border-radius helper classes
.radius-2{border-radius: $radus-2;}
.radius-4{border-radius: $radus-4;}
.radius-6{border-radius: $radus-6;}
.radius-8{border-radius: $radus-8;}
.radius-max{border-radius: $radus-max;}

//backgrounds
.background-color--lightgrey {
    background-color:  $light-grey;
}
.background-color--primary {
    background-color:  $primary-color;
}

.h-50 {
    height: 50px !important;
}
.h-40 {
    height: 40px !important;
}
.mw-32{
    min-width: 32px !important;
}