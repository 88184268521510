// lib
@import "./assets/scss/reset.scss";
@import "./assets/scss/bootstrap-pre.scss";
@import 'bootstrap/scss/bootstrap';
@import "./assets/scss/bootstrap-post.scss";
@import "./assets/scss/recaptcha.scss";
@import "./assets/scss/fonts.scss";
@import "./assets/scss/variables.scss";
@import "./assets/scss/helpers.scss";
@import "./assets/scss/tooltips.scss";
@import "./assets/scss/icons.scss";
@import "./assets/scss/tables.scss";
@import "./assets/scss/fields.scss";
@import "./assets/scss/buttons.scss";
@import "./assets/scss/modals.scss";
@import "./assets/scss/popovers.scss";
@import "./assets/scss/typeahead.scss";
@import "./assets/scss/toasts.scss";
@import "./assets/scss/stamps.scss";
@import "./assets/scss/page.scss";
@import "./assets/scss/animations.scss";
@import "./assets/scss/shared.scss";
@import "./assets/scss/skeleton.scss";
@import "./assets/scss/richtext.scss";

// domain global
.app{
    // public
    &.app--public{

    }
    // admin
    &.app--admin{

    }
}

// version
._version{
    position: fixed;
    bottom: 6px;
    right: 8px;
    z-index: 999999;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    background-color: #999999;
    padding: 5px 7px;
    padding-top: 6px;
    border-radius: 4px;
    opacity: 0.2;
    transition: 0.1s ease;
    padding-left: 23px;
    background-image: url(/assets/img/icons/_version.svg);
    background-size: 13px;
    background-position: center left 6px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
}
.noscroll{
    overflow: hidden;
}
// index
body {
    word-break: break-word;
    color: $text-color;
    // @fontfamily
    // font-family: 'Fira Sans', sans-serif;
    font-family: 'Gotham', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}
app-root{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
body * {
    font-family: 'Gotham', sans-serif;
}
// .page{
//     padding: 28px 40px 40px 40px;
// }
h1, h2, h3{
    color: $primary-color;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 12px;
}
h1, .h1{
    font-size: 24px;
    margin-bottom: 16px;
}
h2{
    font-size: 18px;
}
h3{
    font-size: 18px;
}
p, .p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
ul, ol{
    * {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}
blockquote{
    background-color: $light-purple;
    color: $primary-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-left: none;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
ul, ol{
    margin-bottom: 0;
    &.display-value{
        &>li{
            padding: 8px;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid $light-grey;
            &:first-of-type{
                margin-top: 8px;
                border-top: 1px solid $light-grey;
            }
        }
    }
}
ul li {color: $text-color;}
ol li {color: $text-color;}
.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
// general
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


@media screen and (min-width: 992px) {
    
}