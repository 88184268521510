@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Book.otf') ;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Medium.otf') ;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Gotham";
    src: url('../font/Gotham-Bold.otf') ;
    font-weight: 700;
    font-style: normal;
}
