// bootstrap resets
p{
    margin: 0;
}
h1, h2, h3, h4{
    margin: 0;
    font-weight: 400;
}
strong{
    font-weight: 500;
}