$primary-color: #702283;
$spinner-color: #702283;
$text-color: #000;
$secundary-color: #F9F871;
$tertiary-color: #DCE666;
$light-grey: #F5F3F5;
$light-purple: #FAF9FA;
$text-color: #000;
$text-grey: #635F64;
$placeholder-color: #A8A4A9;

$box-shadow: rgba(18, 10, 20, 0.15);
$field-border-color: #E5E3E6;
$field-border-color-purple: #8C4BA3;
$error-red: #E03366;

$spacing-64: 64px;
$spacing-48: 48px;
$spacing-36: 36px;
$spacing-32: 32px;
$spacing-24: 24px;
$spacing-16: 16px;
$spacing-8: 8px;
$spacing-6: 6px;

$radus-max: 999px;
$radus-8: 8px;
$radus-6: 6px;
$radus-4: 4px;
$radus-2: 2px;