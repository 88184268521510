.page{
    flex-grow: 1;
    position: relative;
    &.page--public{
        
    }
    &.page--private{
        
    }
    .page-head{
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            h1{
                color: $text-color;
                font-size: 24px;
                font-weight: 500;
                line-height: 30px;
            }
            app-breadcrumbs{
                
            }
        }
        .right{
            .buttons{
                display: flex;
                gap: 8px;
            }
        }
    }
}