.popover{
    // @fontfamily
    // font-family: 'Fira Sans', sans-serif;

    max-width: none;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    .popover-arrow{
        display: none !important;
    }
    .popover-body{
        padding: 0;
        border-radius: 2px;
        box-shadow: 0px 8px 12px 0px #0000001F;
        border: 1px solid #EAF0F3;
        background-color: #fff;
        overflow: hidden;
    }
}

.popover--actions{
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.20);
    background-color: #fff;
    max-width: 400px;
    min-width: 0;
    overflow: hidden;
    .popover-arrow{
        display: none;
    }
    .popover-body{
        overflow-y: auto;
        padding: 0;
        .options{
            display: flex;
            flex-direction: column;
            .option{
                padding: 8px;
                font-size: 14px;
                line-height: 20px;
                position: relative;
                display: flex;
                align-items: center;
                gap: 8px;
                text-decoration: none;
                border-bottom: 1px solid #E5E5E5;
                color: $text-color;
                cursor: pointer;
                &:hover{
                    background-color: #FAFCFF;
                }
                &:last-of-type{
                    border-bottom: none;
                }
            }
        }
    }
}

.popover--typeahead{
    width: 100%;
    top: 4px !important;
    .popover-body{
        border: 1px solid $field-border-color;
        border-radius: 4px;
        padding: 10px 8px 8px;
        // padding-top: 20px;
        box-shadow: 0px 1px 6px 0px #00000026;
        max-width: calc(100vw - 32px);
        background-color: #fff;
        transform: translateY(-9px);
        overflow: visible;
        .popover--inner{
            max-height: 600px;
            display: flex;
            flex-direction: column;
        }
        .results-wrap{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: -16px;
            overflow-y: auto;
            overflow-x: hidden;
            .result-row{
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #F3F3F3;
                padding-bottom: 10px;
                padding-top: 10px;
                margin-left: -16px;
                margin-right: -16px;
                padding-left: 16px;
                padding-right: 16px;
                width: calc(100% + 32px);
                cursor: pointer;
                &:hover{
                    background-color: #f9f9f9;
                }
                .title-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 2px;
                    .title-role-wrap{
                        max-width: 80%;
                    }
                    .title{
                        display: inline-block;
                        color: #151515;
                        font-size: 14px;
                        line-height: 24px;
                        margin-right: 8px;
                        font-weight: 500;
                    }
                    .role{
                        line-height: 24px;
                        display: inline-block;
                        background-color: #EAF0F3;
                        padding: 0 6px;
                        border-radius: 4px;
                    }
                    .optionalInfo{
                        display: inline-block;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                .descr-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    &>span{
                        line-height: 24px;
                        color: #4F5563;
                    }
                    &>i{
                        background-color: #929292;
                        width: 3px;
                        height: 3px;
                        border-radius: 99px;
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
            }
        }
        .no-results{
            color: #ACB5C1;
            padding-top: 10px;
            padding-bottom: 16px;
        }
        .clear-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid $field-border-color;
            padding: 0 16px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            cursor: pointer;
            color: #929292;
            text-decoration: underline;
            margin-top: 16px;
            padding-top: 8px;
            margin-bottom: -8px;
        }
    }
}