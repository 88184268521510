@import "./variables.scss";

.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    // auth

    &.icon-auth-lock{
        
    }
    &.icon-magnifying-glass{
        width: 24px;
        height: 24px;
        background-image: url(/assets/img/icons/magnifying-glass.svg);
    }
    &.icon-close-x{
        width: 24px;
        height: 24px;
        background-image: url(/assets/img/icons/close-x.svg);
    }
    &.icon-close-circle{
        width: 32px;
        height: 32px;
        background-image: url(/assets/img/icons/close-circle.svg);
    }
    &.icon-chevron-up{
        width: 16px;
        height: 16px;
        background-image: url(/assets/img/icons/chevron-up.svg);
    }
    &.icon-login{
        width: 16px;
        height: 16px;
        background-image: url(/assets/img/icons/login.svg);
    }
    &.icon-login--white{
        width: 16px;
        height: 16px;
        background-image: url(/assets/img/icons/login--white.svg);
    }
    &.icon-info{
        width: 24px;
        height: 24px;
        background-image: url(/assets/img/icons/info.svg);
    }
    &.icon-pencil{
        width: 24px;
        height: 24px;
        background-image: url(/assets/img/icons/pencil.svg);
    }

    &.experience-score{
        &.experience-score--1{
            display: block;
            width: 50px;
            height: 50px;
            background-image: url(/assets/img/icons/experience_score-1.svg);
            &.filled{
                background-image: url(/assets/img/icons/experience_filled_score-1.svg);
            }
        }
        &.experience-score--2{
            display: block;
            width: 50px;
            height: 50px;
            background-image: url(/assets/img/icons/experience_score-2.svg);
            &.filled{
                background-image: url(/assets/img/icons/experience_filled_score-2.svg);
            }
        }
        &.experience-score--3{
            display: block;
            width: 50px;
            height: 50px;
            background-image: url(/assets/img/icons/experience_score-3.svg);
            &.filled{
                background-image: url(/assets/img/icons/experience_filled_score-3.svg);
            }
        }
        &.experience-score--4{
            display: block;
            width: 50px;
            height: 50px;
            background-image: url(/assets/img/icons/experience_score-4.svg);
            &.filled{
                background-image: url(/assets/img/icons/experience_filled_score-4.svg);
            }
        }

    }

    &.thumbnail-logo{
        width: 32px;
        height: 32px;
        background-image: url(/assets/img/misc/logo-collapsed-meldpunt.svg);
    }



    &.icon-size--24{
        width: 24px;
        height: 24px;
    }
}