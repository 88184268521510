.grecaptcha-badge {
    opacity: 0;
    pointer-events: none;
    transition: right 0.3s ease 0s, opacity .2s ease 0s !important;
    bottom: 50px !important;
    z-index: 10;
}
.show-captcha .grecaptcha-badge {
    opacity: 1;
    pointer-events: all;
}