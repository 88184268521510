input.responsive-typeahead{
    background-color: #fff;
    border: 1px solid #E5E3E6;
    height: 36px;
    padding-left: 8px;
    padding-right: 28px;
    // padding-right: 88px;
    background-image: url(/assets/img/icons/icon-search-gray.svg); 
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center right 8px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    font-size: 14px;
    line-height: 24px;
    min-height: 40px;
    &::placeholder{
        color: $placeholder-color !important;
    }

    &:focus{
        border-color: $primary-color;
        box-shadow: 0 0 0 0.25rem transparentize($color: $primary-color, $amount: .75)
    }
}

.dropdown-menu {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px $box-shadow;
    max-height: 450px !important;
    overflow-y: auto;

    .dropdown-item{

        padding-top: 12px !important;
        padding-bottom: 12px !important;
        border-bottom: 1px solid $field-border-color;
        text-wrap: wrap;

        font-family: "Gotham" sans-serif !important;
        font-size: 15px !important;
        font-weight: 400  !important;
        line-height: 18px  !important;

        .ngb-highlight{
            font-weight: inherit;
            background: #F9F871;
        }
    }
    .dropdown-item.active{
        color: inherit !important;
        background-color: $light-purple !important;
    }
}