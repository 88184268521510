

.richtext-print{

    h2{
        margin-top: 12px;
    }
    p, li{
        margin-bottom: 12px;
    }
    ul{
        list-style: disc !important;  
    }
    ol{
        list-style: decimal;
    }
    &.richtext-print--preview{
        * {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0;
            margin-top: 0;
            font-weight: 400;
        }

        ul{
            list-style: disc;
        }
        ol{
            list-style: decimal;
        }
        
    }
}