// toastr
#toast-container{
    .ngx-toastr{
        box-shadow: none;
        padding: 12px 16px;
        padding-left: 44px;
        background: #31302E;
        background: #355477;
        margin: 0 0 10px;
        width: auto;
        max-width: 340px;
        border-radius: 8px;
        box-shadow: 0 0 10px #59595952;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        .toast-close-button{
            span{
                display: none;
            }
        }
    }
    &.toast-bottom-right {
        right: 20px;
        bottom: 10px;
    }
    .toast-message{
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    .toast-warning{
        background-image: url(/assets/img/components/toastr/warning.svg);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center left 16px;
    }
    .toast-error{
        background-image: url(/assets/img/components/toastr/error.svg);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center left 16px;
    }
    .toast-info{
        background-image: url(/assets/img/components/toastr/info.svg);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center left 16px;
    }
    .toast-success{
        background-image: url(/assets/img/components/toastr/success.svg);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center left 16px;
    }
    
}