.app-button{

    border-radius: $radus-max;
    padding: 12px 24px;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    text-wrap: balance;
    max-width: 100%;
    cursor: pointer;
    display: inline-block;

    &.fill-available{
        width: -webkit-fill-available;
    }
    &.primary-button, &.primary-default{
        color: $secundary-color;
        background-color: $primary-color;
        &:hover{
            background-color: transparentize($color:  $primary-color, $amount: .2);
        }
    }
    &.primary-button--inverted{
        color: $primary-color;
        background-color: $tertiary-color;
        &:hover{
            background-color: #EDF3B2
        }
    }
    &.primary-button--highlighted{
        color: #fff !important;
        background-color: #00A976;
        
        &:hover{
            background-color: transparentize($color:#00A976, $amount: .2) !important;
        }
    }
    &.secondary-button{
        color: $primary-color;
        border: 1px solid $primary-color;
        
    }

    &:hover{
        box-shadow: 0px 4px 12px 0px rgba(18, 10, 20, 0.15);
    }
}

a{
    transition: all 250ms ease;
    // transition: translate 180ms ease;
    &:focus, &:focus-visible{
        outline: none;
    }
}

.app-button, a{
    transition: all 250ms ease;
    
    .icon, .im-icon{
        transition: all 250ms ease;
    }

    &:not(.no-icon-hover):hover{
        .icon, .im-icon{
            translate: 4px;
        }
    }
}

.link{
    font-family: 'Gotham' sans-serif;
    color: $primary-color;
    text-decoration-line: underline;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: translate 180ms ease;
    
    &.link-center{
        text-align: center;
        display: inline-block;
        width: 100%;
    }

}