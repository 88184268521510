// label
.field .label, .standalone-label {
    display: block;
    color: $text-color;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-bottom: 5px;
    span.optional{
        // color: #808080;
        // font-weight: 300;
        // margin-left: 8px;
    }
}

.field-label{
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    margin-bottom: 8px;
}

// error
.field {
    .after-string{
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
    }
    .error-field{
        border: 1px solid $red !important;
    }
    .error-field-display {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-top: 2px;
        font-weight: 500;
    }
}
.standalone-error {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $red;
    margin-top: 2px;
}

// default placeholder
::placeholder {
    color: #BCBCBD;
    opacity: 1;
    font-weight: 400;
}

// ngb datepicker
body{
    ngb-datepicker{
        box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.2);
        border: none !important;
        border-radius: 8px;
        z-index: 1990 !important;
    }
    .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ngb-dp-header{
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 5px;
        &::after{
            content: '';
            position: absolute;
            height: 1px;
            background-color: #D2D9D6;
            left: 12px;
            right: 12px;
            bottom: 0;
        }
    }
    .ngb-dp-navigation-select{
        .form-select{
            &:first-of-type{
                    border: 0 !important;
                    background: none !important;
                    outline: none !important;
                    text-align: right !important;
                    font-weight: 500 !important;
                    color: #000 !important;
                    box-shadow: none !important;
                    font-size: 14px !important;
                    padding-right: 2px !important;
            }
            &:last-of-type{
                    border: 0 !important;
                    background: none !important;
                    font-size: 14px !important;
                    outline: none !important;
                    text-align: left !important;
                    font-weight: 500 !important;
                    box-shadow: none !important;
                    color: #000 !important;
                    padding-left: 2px !important;
            }
        }
    }
    .ngb-dp-arrow{
        &:first-of-type{
            .ngb-dp-navigation-chevron{
                width: 7px !important;
                height: 7px !important;
                border-width: 1px 1px 0 0 !important;
                display: block !important;
                color: $primary-color;
            }
        }
        &:last-of-type{
            .ngb-dp-navigation-chevron{
                width: 7px !important;
                height: 7px !important;
                border-width: 1px 1px 0 0 !important;
                display: block !important;
                color: $primary-color;
            }
        }
    }
    .ngb-dp-header{
        background-color: transparent !important;
    }
    .ngb-dp-weekdays{
        background-color: transparent !important;
        border-bottom: none !important;
        cursor: default;
    }
    .bg-primary{
        background-color: $primary-color !important;
        outline: none !important;
    }
    .ngb-dp-weekday{
        color: #6D6D6C !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 12px !important;
    }
    .ngb-dp-day{
        display: flex;
        align-items: center;
        justify-content: center;
        &.ngb-dp-today{
            .btn-light{
                outline: 1px solid var(--bs-border-color);
            }
        }
        .btn-light{
            font-size: 14px !important;
            border-radius: 99px;
            width: 28px;
            height: 28px;
            align-self: center;
            line-height: 28px;
        }
    }
}