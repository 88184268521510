.skeleton{
    background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right / 300% 100%;
    cursor: progress;
    animation: skeleton-loading 1.5s linear infinite;
   
    

    &.skeleton--paragraph{
        height: 1rem;
        display: block;
        margin-bottom: 12px;
    }


    &.skeleton--insverse{
        background: linear-gradient(90deg, rgba(242, 242, 242, 0.667) 40%, #efefefaa, rgba(242, 242, 242, 0.667) 60%) right / 300% 100%;

    }
}

@keyframes skeleton-loading {
    from {
      background-position: right;
    }
    to {
      background-position: left;
    }
  }