table{
    word-break: initial;
}
table.app-table{
    width: 100%;
    tr{
        th{
            background: lightgray;
            padding: 8px;
            color: $primary-color;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            white-space: nowrap;
            span{
                color: $primary-color;
                font-size: 12px;
                font-weight: 500;
                line-height: 1;
                white-space: nowrap;
            }
            &.sortable{
                cursor: pointer;
                user-select: none;
            }
            // @todo
            // i.sortable-icon{
            //     position: relative;
            //     margin-left: 2px;
            //     width: 14px;
            //     height: 14px;
            //     flex-shrink: 0;
            //     display: inline-block;
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     background-position: center;
            //     background-image: url(/assets/img/icons/sortable-icon.svg);
            //     &.sortable-icon--asc{
            //         background-image: url(/assets/img/icons/sortable-icon--asc.svg);
            //     }
            //     &.sortable-icon--desc{
            //         background-image: url(/assets/img/icons/sortable-icon--desc.svg);
            //     }
            // }
        }
        td{
            vertical-align: top;
            background: #fff;
            font-size: 14px;
            line-height: 24px;
            padding: 0;
            overflow: hidden;
            height: 1px;
            .display, .display *{
                line-height: 24px;
            }
            &>.pad{
                padding: 12px 8px;
                color: $text-color;
                text-decoration: none !important;
                display: block;
                height: 100%;
                width: 100%;
                .actions-button-wrap{
                    display: flex;
                    align-items: center;
                    align-items: flex-start;
                    transition: .1s ease;
                    &[aria-describedby^="ngb-popover"]{
                        i.icon-actions{
                            filter: brightness(.3);
                        }
                    }
                    .actions-button{
                        padding: 3px 0;
                        display: inline-flex;
                        transition: .1s ease;
                        i.icon-actions{
                            height: 26px;
                        }
                        &:hover{
                            filter: brightness(.3);
                        }
                    }
                }
            }
            border-bottom: 1px solid #E5E5E5;
            &.td--content-h-24{
                &>.pad{
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
            &.td--content-h-20{
                &>.pad{
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
            &.td--content-h-00{
                &>.pad{
                    
                }
            }
            &.td--title{
                &>.pad{
                    color: $primary-color;
                    font-weight: 500;
                }
            }
        }
        &:last-of-type td{
            border-bottom: none;
        }
    }
    &.app-table-clickable{
        tr:hover td{
            background-color: #FAFCFF;
        }
        tr td{
            cursor: pointer;
        }
    }
    &.app-table--alt{
        tr{
            th{
                span{
                    color: #729BC8;
                }
            }
            td{
                &>.pad{
                    
                }
            }
        }
    }
    tr{
        th:first-of-type{
            padding-left: 12px;
            border-top-left-radius: 6px;
        }
        th:last-of-type{
            padding-right: 12px;
            border-top-right-radius: 6px;
        }
        td:first-of-type{
            &>.pad{padding-left: 12px;}
        }
        td:last-of-type{
            &>.pad{padding-right: 12px;}
        }
        &:last-of-type{
            td:first-of-type{
                border-bottom-left-radius: 8px;
            }
            td:last-of-type{
                border-bottom-right-radius: 8px;
            }
        }
    }
}

// moz cell height
@-moz-document url-prefix() {
    table.app-table{
        tr{
            td{
                height: 100%;
            }
        }
    }
}